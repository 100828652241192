import { EmailIcon } from "@chakra-ui/icons";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import {
  useGetRolesQuery,
  useInviteUserMutation,
  useGetClientsQuery,
  PermissionEnum,
} from "generated/graphql";
import { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

interface UserCreateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (user: {
    id: string;
    name: string;
    imageUrl: string;
    email: string;
  }) => void;
}

export const UserCreateModal = ({
  isOpen,
  onClose,
  onCreate,
}: UserCreateModalProps) => {
  const { mutateAsync: createUser } = useInviteUserMutation();
  const { data: roles } = useGetRolesQuery();
  const { data: clients } = useGetClientsQuery();
  const toast = useToast();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    roleId: "",
    clientId: "",
    managedClientIds: [] as string[],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [parent] = useAutoAnimate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const res = await createUser(formData);
      onCreate(res.invite!);
      toast({
        title: "User invited successfully",
        description: "An invitation email has been sent to the user.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: "Couldn't invite user",
        description: "There was an error inviting the user. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const selectedRole = roles?.roles.find((r) => r.id === formData.roleId);
  const canViewClientDashboard = selectedRole?.permissions.some(
    (p) => p.permission === PermissionEnum.CanViewClientDashboard
  );
  const canViewAgencyDashboard = selectedRole?.permissions.some(
    (p) => p.permission === PermissionEnum.CanViewAgencyDashboard
  );

  const clientOptions =
    clients?.clients.map((client) => ({
      value: client.id,
      label: client.name,
    })) || [];

  const roleOptions =
    roles?.roles.map((role) => ({
      value: role.id,
      label: role.name,
    })) || [];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent>
        <ModalHeader borderBottom="1px" borderColor="gray.200" pb={4}>
          <Text fontSize="xl" fontWeight="bold">
            Invite New User
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={6} pb={8}>
          <Text
            fontSize="sm"
            color="gray.600"
            mb={6}
            p={4}
            bg="blue.50"
            borderRadius="md"
            borderLeft="4px"
            borderColor="blue.500"
          >
            You can invite both agency team members and client employees to the
            system. Agency members can manage multiple clients, while client
            employees will have access to their own company's dashboard. All
            users will receive an email invitation to set up their account.
          </Text>

          <form ref={parent} onSubmit={handleSubmit}>
            <FormControl isRequired mb={6}>
              <FormLabel fontWeight="medium">Full Name</FormLabel>
              <Input
                size="lg"
                placeholder="e.g., John Smith"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                _focus={{
                  borderColor: "blue.500",
                  boxShadow: "0 0 0 1px blue.500",
                }}
              />
              <FormHelperText color="gray.500">
                Enter the user's full name as it should appear in the system
              </FormHelperText>
            </FormControl>

            <FormControl isRequired mb={6}>
              <FormLabel fontWeight="medium">Email Address</FormLabel>
              <Input
                size="lg"
                type="email"
                placeholder="user@example.com"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                _focus={{
                  borderColor: "blue.500",
                  boxShadow: "0 0 0 1px blue.500",
                }}
              />
              <FormHelperText color="gray.500">
                The invitation will be sent to this email address
              </FormHelperText>
            </FormControl>

            <FormControl isRequired mb={6}>
              <FormLabel fontWeight="medium">User Role</FormLabel>
              <Select
                options={roleOptions}
                value={roleOptions.find(
                  (option) => option.value === formData.roleId
                )}
                onChange={(option) =>
                  setFormData({ ...formData, roleId: option?.value || "" })
                }
                placeholder="Select a role"
                size="lg"
                chakraStyles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "md",
                  }),
                }}
              />
              <FormHelperText color="gray.500">
                Select the appropriate role and permissions for this user
              </FormHelperText>
            </FormControl>

            {canViewClientDashboard && (
              <FormControl mb={6}>
                <FormLabel fontWeight="medium">
                  <Flex justify="space-between" align="center" width="100%">
                    Client Employer
                    <Text color="gray.500" fontSize="sm">
                      (Optional)
                    </Text>
                  </Flex>
                </FormLabel>
                <Select
                  options={clientOptions}
                  value={clientOptions.find(
                    (option) => option.value === formData.clientId
                  )}
                  onChange={(option) =>
                    setFormData({ ...formData, clientId: option?.value || "" })
                  }
                  placeholder="Select a client"
                  isClearable
                  size="lg"
                  chakraStyles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "md",
                    }),
                  }}
                />
                <FormHelperText color="gray.500">
                  Select if this user is an employee of a specific client
                  company
                </FormHelperText>
              </FormControl>
            )}

            {canViewAgencyDashboard && (
              <FormControl mb={6}>
                <FormLabel fontWeight="medium">
                  <Flex justify="space-between" align="center" width="100%">
                    Managed Clients
                    <Text color="gray.500" fontSize="sm">
                      (Optional)
                    </Text>
                  </Flex>
                </FormLabel>
                <Select
                  isMulti
                  options={clientOptions}
                  value={clientOptions.filter((option) =>
                    formData.managedClientIds.includes(option.value)
                  )}
                  onChange={(options) =>
                    setFormData({
                      ...formData,
                      managedClientIds: options.map((option) => option.value),
                    })
                  }
                  placeholder="Select clients"
                  size="lg"
                  chakraStyles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "md",
                    }),
                  }}
                />
                <FormHelperText color="gray.500">
                  For agency users: select the client companies this person will
                  manage
                </FormHelperText>
              </FormControl>
            )}

            <Flex justify="space-between" mt={8}>
              <Button
                variant="ghost"
                onClick={onClose}
                size="lg"
                _hover={{ bg: "gray.100" }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                leftIcon={<EmailIcon />}
                colorScheme="blue"
                size="lg"
                isLoading={isSubmitting}
                loadingText="Sending invitation..."
                px={8}
              >
                Send Invitation
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
