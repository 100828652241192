import { Box, Text, Spinner, Flex } from "@chakra-ui/react";
import { SearchItem } from "../SearchItem";
import { useInfiniteQuery } from "react-query";
import { graphqlFetcher } from "generated/fetcher";
import {
  SearchAuthorsDocument,
  SearchAuthorsQuery,
  SearchAuthorsQueryVariables,
} from "generated/graphql";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Page, SelectableItem } from "../types";

interface JournalistSearchResultsProps {
  query: string;
  isSelected: boolean;
}

const PAGE_SIZE = 3;

export const JournalistSearchResults = ({
  query,
  isSelected,
}: JournalistSearchResultsProps) => {
  const navigate = useNavigate();

  const { data, isLoading } = useInfiniteQuery<SearchAuthorsQuery>(
    ["quickSearchJournalists", query],
    async ({ pageParam = 0 }) => {
      return await graphqlFetcher<
        SearchAuthorsQuery,
        SearchAuthorsQueryVariables
      >(SearchAuthorsDocument, {
        query,
        skip: pageParam * PAGE_SIZE,
        take: PAGE_SIZE,
      })();
    },
    {
      enabled: query.length >= 3,
      getNextPageParam: (lastPage) => {
        if (lastPage.searchAuthors.length < PAGE_SIZE) return undefined;
        return 1; // We only want to show the first page in quick search
      },
    }
  );

  const journalists = data?.pages[0]?.searchAuthors || [];

  if (isLoading) {
    return (
      <Box px="4" py="2">
        <Spinner size="sm" />
      </Box>
    );
  }

  if (journalists.length === 0) return null;

  return (
    <>
      <Flex justifyContent="space-between" px="4" py="2">
        <Text
          fontSize="xs"
          fontWeight="medium"
          color="gray.500"
          textTransform="uppercase"
        >
          Journalists
        </Text>
        <Text
          as="a"
          href={`/authors?query=${encodeURIComponent(query)}`}
          fontSize="xs"
          color="blue.500"
          _hover={{ textDecoration: "underline" }}
        >
          See all
        </Text>
      </Flex>
      {journalists.map((journalist) => {
        const item: SelectableItem = {
          id: `journalist-${journalist.id}`,
          type: "journalist",
          element: {
            title: journalist.name,
            description:
              journalist.publicationHistory[0]?.publication.name || "",
            type: "route",
            icon: FaUser,
            path: `/authors/${journalist.id}`,
          },
          section: "Journalists",
        };

        return (
          <SearchItem
            key={item.id}
            item={item}
            onClick={() => {
              // Add to recent pages in localStorage
              const stored = localStorage.getItem("recentSearchPages");
              const recentPages = stored ? JSON.parse(stored) : [];
              const newRecentPage = {
                ...item.element,
                timestamp: Date.now(),
                type: "journalist" as const,
              };
              const updated = [
                newRecentPage,
                ...recentPages.filter(
                  (p: any) => p.path !== (item.element as Page).path
                ),
              ].slice(0, 5);
              localStorage.setItem(
                "recentSearchPages",
                JSON.stringify(updated)
              );

              navigate(`/authors/${journalist.id}`);
            }}
            isSelected={isSelected}
          />
        );
      })}
    </>
  );
};
