import {
  Avatar,
  Box,
  Divider,
  Flex,
  Icon,
  LinkBox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  Tooltip,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaCog, FaUserPlus } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { NotificationsTray } from "../notifications/NotificationsTray";
import { PermissionEnum } from "generated/graphql";
import { addDays, formatDistance } from "date-fns";
import { UserCreateModal } from "../users/UserCreateModal";
import { SearchBox } from "./search/SearchBox";

export const TOP_BAR_HEIGHT = "8vh";

export const TopBar = () => {
  const { name, imageUrl, email, workspace, logOut, role } = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  return (
    <Box
      position="fixed"
      shadow="xs"
      zIndex={100}
      w="100%"
      backdropFilter="blur(10px)"
      h={TOP_BAR_HEIGHT}
      backgroundColor="yaarnYellow.100"
    >
      <Flex justifyContent="end" w="100%">
        <Box mr="8" my="auto">
          <SearchBox className="mr-4" />
        </Box>
        <Flex alignItems="center" mr="16">
          {role.permissions.includes(PermissionEnum.CanEditUsers) && (
            <>
              <Button
                leftIcon={<FaUserPlus />}
                size="sm"
                colorScheme="blue"
                mr="4"
                onClick={() => setIsInviteModalOpen(true)}
              >
                Invite User
              </Button>
              <UserCreateModal
                isOpen={isInviteModalOpen}
                onClose={() => setIsInviteModalOpen(false)}
                onCreate={() => setIsInviteModalOpen(false)}
              />
            </>
          )}

          {!workspace.hasPaidSubscription && (
            <Tooltip label="Remaining trial period">
              <Text
                color="gray.600"
                fontSize="sm"
                fontWeight="medium"
                mr="4"
                textAlign="center"
              >
                {formatDistance(
                  addDays(new Date(workspace.createdAt), 14),
                  new Date()
                )}{" "}
                remaining
              </Text>
            </Tooltip>
          )}
          {role.permissions.includes(PermissionEnum.CanEditWorkspace) ? (
            <Link to="/workspace">
              <Flex
                transition="ease 400ms all"
                _hover={{ backgroundColor: "rgba(0,0,0,0.05)" }}
                align="center"
                h={TOP_BAR_HEIGHT}
                pl="2"
              >
                <Avatar
                  borderRadius="0"
                  name={workspace.name}
                  size="xs"
                  mr="2"
                  src={process.env.REACT_APP_FILE_URL + workspace.logo}
                />
                <Text
                  color="gray.600"
                  fontSize="sm"
                  fontWeight="medium"
                  mr="4"
                  textAlign="center"
                >
                  {workspace.name}
                </Text>
              </Flex>
            </Link>
          ) : (
            <Flex align="center">
              <Avatar
                borderRadius="0"
                name={workspace.name}
                size="xs"
                mr="2"
                src={process.env.REACT_APP_FILE_URL + workspace.logo}
              />
              <Text
                color="gray.600"
                fontSize="sm"
                fontWeight="medium"
                mr="4"
                textAlign="center"
              >
                {workspace.name}
              </Text>
            </Flex>
          )}

          <Divider orientation="vertical" />
          <Menu>
            <MenuButton
              transition="ease 400ms all"
              _hover={{ backgroundColor: "rgba(0,0,0,0.05)" }}
              textAlign="left"
            >
              <LinkBox h={TOP_BAR_HEIGHT}>
                <Flex
                  h="100%"
                  py="2"
                  mx="2"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box mr="4">
                    <Text fontWeight="bold" fontSize="sm">
                      {name}
                    </Text>
                    <Text fontSize="smaller">{email}</Text>
                  </Box>
                  <Avatar
                    size="sm"
                    name={name}
                    src={process.env.REACT_APP_FILE_URL + imageUrl}
                  />
                </Flex>
              </LinkBox>
            </MenuButton>
            <MenuList py="0">
              <MenuItem
                icon={<Icon as={FaCog} color="gray.500" />}
                as={Link}
                to="/profile"
              >
                Profile
              </MenuItem>
              <MenuItem
                icon={
                  isLoggingOut ? (
                    <Spinner size="sm" />
                  ) : (
                    <Icon as={FiLogOut} color="gray.500" />
                  )
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsLoggingOut(true);
                  logOut();
                }}
              >
                Log out
              </MenuItem>
            </MenuList>
          </Menu>

          <Divider orientation="vertical" />

          <NotificationsTray />
        </Flex>
      </Flex>
    </Box>
  );
};
