import { Box, Text } from "@chakra-ui/react";
import { SelectableItem } from "./types";
import { SearchItem } from "./SearchItem";
import { WiStars } from "react-icons/wi";

interface RecentSearchesProps {
  selectableItems: SelectableItem[];
  selectedId: string | null;
  onItemClick: (item: SelectableItem) => void;
  searchValue: string;
  onAskAI: (question: string) => void;
}

export const RecentSearches = ({
  selectableItems,
  selectedId,
  onItemClick,
  searchValue,
  onAskAI,
}: RecentSearchesProps) => {
  // Group items by section
  const itemsBySection = selectableItems.reduce((acc, item) => {
    if (!acc[item.section]) {
      acc[item.section] = [];
    }
    acc[item.section].push(item);
    return acc;
  }, {} as Record<string, SelectableItem[]>);

  // Separate recent conversations from other sections
  const { "Recent Conversations": recentConversations, ...otherSections } =
    itemsBySection;

  return (
    <>
      {/* Show all sections except Recent Conversations first */}
      {Object.entries(otherSections).map(([section, sectionItems]) => (
        <Box key={section}>
          <Box px="4" py="2">
            <Text
              fontSize="xs"
              fontWeight="medium"
              color="gray.500"
              textTransform="uppercase"
            >
              {section}
            </Text>
          </Box>
          {sectionItems.map((item) => (
            <SearchItem
              key={item.id}
              item={item}
              onClick={onItemClick}
              isSelected={item.id === selectedId}
              isSubItem={item.type === "subpage"}
            />
          ))}
        </Box>
      ))}

      {/* Show AI option */}
      <Box px="4" py="2">
        <Text
          fontSize="xs"
          fontWeight="medium"
          color="gray.500"
          textTransform="uppercase"
        >
          Ask AI
        </Text>
      </Box>
      <SearchItem
        item={{
          id: "ask-ai-option",
          type: "ai" as const,
          element: {
            title: "Ask AI a question",
            description: "Get AI assistance with your question",
            type: "conversation" as const,
            icon: WiStars,
            path: "",
          },
          section: "Ask AI",
        }}
        onClick={() => onAskAI(searchValue)}
        isSelected={selectedId === "ask-ai-option"}
        isSubItem={false}
      />

      {/* Show Recent Conversations at the bottom */}
      {recentConversations && recentConversations.length > 0 && (
        <Box>
          <Box px="4" py="2">
            <Text
              fontSize="xs"
              fontWeight="medium"
              color="gray.500"
              textTransform="uppercase"
            >
              Recent Conversations
            </Text>
          </Box>
          {recentConversations.map((item) => (
            <SearchItem
              key={item.id}
              item={item}
              onClick={onItemClick}
              isSelected={item.id === selectedId}
              isSubItem={false}
            />
          ))}
        </Box>
      )}
    </>
  );
};
