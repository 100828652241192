import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBwYmsAqWGXsmvWIpL8X_u6NTg9DI-9EQY",
  authDomain: "yaarn-581e9.firebaseapp.com",
  projectId: "yaarn-581e9",
  storageBucket: "yaarn-581e9.firebasestorage.app",
  messagingSenderId: "461759407310",
  appId: "1:461759407310:web:911427ac95c3f227935c9f",
  measurementId: "G-GRE91XWTQ6",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
