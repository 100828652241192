import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import { useAuth } from "hooks/useAuth";
import { useState } from "react";
import { FaTasks, FaBookOpen, FaChartLine } from "react-icons/fa";
import { MdBusiness } from "react-icons/md";
import { routesWithPermissions } from "routes";
import { SidebarLink } from "../SidebarLink";

interface SidebarClientLinkProps {
  text: string;
  logoUrl?: string | null;
  fontSize?: string;
  clientId: string;
  isArchived: boolean;
}

export const SidebarClientFolder = ({
  text,
  logoUrl,
  clientId,
  isArchived,
}: SidebarClientLinkProps) => {
  const { hasPermission } = useAuth();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      onOpen={() => setIsPopoverOpen(true)}
      onClose={() => setIsPopoverOpen(false)}
      placement="right"
      isLazy
    >
      <PopoverTrigger>
        <ListItem mx="3">
          <Button
            transition="all 0.2s"
            cursor="pointer"
            _hover={{
              backgroundColor: "yaarnRed.400",
              transform: "translateX(4px)",
            }}
            py="1.5"
            w="100%"
            variant="ghost"
            backgroundColor={isPopoverOpen ? "yaarnRed.400" : undefined}
            px={3}
            borderRadius="md"
            fontSize="sm"
            whiteSpace="normal"
            height="auto"
            minHeight="40px"
          >
            <Flex
              h="100%"
              align="center"
              w="100%"
              color="white"
              justifyContent="space-between"
            >
              <Flex align="center" gap={3}>
                <Avatar
                  size="xs"
                  borderRadius={2}
                  src={process.env.REACT_APP_FILE_URL + logoUrl}
                  name={text}
                  bg="yaarnRed.300"
                  color="white"
                />
                <Text fontWeight="normal" textAlign="left" noOfLines={2}>
                  {text}
                  {isArchived && (
                    <Badge
                      ml={2}
                      colorScheme="yellow"
                      fontSize="2xs"
                      verticalAlign="middle"
                    >
                      Archived
                    </Badge>
                  )}
                </Text>
              </Flex>
              <ChevronRightIcon
                transition="transform 0.2s"
                transform={isPopoverOpen ? "rotate(90deg)" : undefined}
              />
            </Flex>
          </Button>
        </ListItem>
      </PopoverTrigger>

      <Portal>
        <PopoverContent
          backgroundColor="yaarnRed.500"
          color="white"
          w="200px"
          border="none"
          boxShadow="lg"
          _focus={{ outline: "none" }}
        >
          <PopoverArrow backgroundColor="yaarnRed.500" />
          <PopoverBody p={0}>
            <List spacing={1}>
              <SidebarLink
                mx={2}
                py={2}
                onHover={() =>
                  routesWithPermissions["/clients/:id"].element.preload()
                }
                key={`/clients/${clientId}`}
                icon={MdBusiness}
                to={`/clients/${clientId}`}
                text="Profile"
                fontSize="xs"
              />

              {hasPermission(
                routesWithPermissions["/activities/client/:clientId"].permission
              ) && (
                <SidebarLink
                  mx={2}
                  py={2}
                  onHover={() =>
                    routesWithPermissions[
                      "/activities/client/:clientId"
                    ].element.preload()
                  }
                  key={`/activities/client/${clientId}`}
                  icon={FaTasks}
                  to={`/activities/client/${clientId}`}
                  text="Activities"
                  fontSize="xs"
                />
              )}

              {/*hasPermission(
                routesWithPermissions["/program-planner/client/:clientId"]
                  .permission
              ) && (
                <SidebarLink
                  mx={2}
                  py={2}
                  onHover={() =>
                    routesWithPermissions[
                      "/program-planner/client/:clientId"
                    ].element.preload()
                  }
                  key={`/program-planner/client/${clientId}`}
                  icon={FaStickyNote}
                  to={`/program-planner/client/${clientId}`}
                  text="Program Planner"
                  fontSize="xs"
                />
                )*/}

              <SidebarLink
                mx={2}
                py={2}
                onHover={() =>
                  routesWithPermissions[
                    "/coverage-tracker/clients/:id"
                  ].element.preload()
                }
                key={`/coverage-tracker/clients/${clientId}`}
                icon={FaBookOpen}
                to={`/coverage-tracker/clients/${clientId}`}
                text="Coverage"
                fontSize="xs"
              />

              <SidebarLink
                mx={2}
                py={2}
                onHover={() =>
                  routesWithPermissions[
                    "/competitor-analysis/client/:clientId"
                  ].element.preload()
                }
                key={`/competitor-analysis/client/${clientId}`}
                icon={FaChartLine}
                to={`/competitor-analysis/client/${clientId}`}
                text="Competitor analysis"
                fontSize="xs"
              />
            </List>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
