import { ChatbotMessageType } from "components/page-shell/search/types";
import { apiClient } from "./apiClient";

type OnStream = (data: { text: string; isComplete: boolean }) => void;

type ContentWriterUpdate = {
  type: "status" | "context" | "content";
  message?: string;
  data?: string;
  isComplete?: boolean;
  proportion: number;
};

type StreamContentWriterParams = {
  onStream: (data: ContentWriterUpdate) => void;
  clientId: string;
  instructions: string;
  content: string;
};

export const streamContentWriter = async ({
  onStream,
  clientId,
  instructions,
  content,
}: StreamContentWriterParams) => {
  const result = await apiClient.ai.contentWriter({
    body: {
      clientId,
      instructions,
      content,
    },
  });

  if (!(result.status === 200)) {
    throw new Error("Failed to generate piece");
  }

  const reader = result.body;

  (async () => {
    let shouldContinue = true;
    while (shouldContinue) {
      if (!reader) {
        throw new Error("No reader found");
      }

      const decoder = new TextDecoder("utf-8");
      const { value: chunk, done: readerDone } = await reader.read();

      if (readerDone) {
        onStream({
          type: "status",
          message: "Content writer completed",
          isComplete: true,
          proportion: 1,
        });
        shouldContinue = false;
        return;
      }

      const chunkString = decoder.decode(chunk);
      const jsonStrings = chunkString.split("\n").filter((str) => str.trim());

      for (const jsonStr of jsonStrings) {
        try {
          const parsed = JSON.parse(jsonStr);
          onStream(parsed);
        } catch (e) {
          console.warn("Failed to parse JSON chunk:", jsonStr);
        }
      }
    }
  })();

  return reader;
};

interface StreamChatProps {
  onStream: OnStream;
  bodyData: ChatbotMessageType[];
}

export const streamChat = async ({ onStream, bodyData }: StreamChatProps) => {
  const result = await apiClient.ai.chat({
    body: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      history: bodyData.map((x) => ({
        role: x.from,
        content: x.message,
      })),
    },
  });

  if (!(result.status === 200)) {
    throw new Error("Failed to generate piece");
  }

  const reader = result.body;

  (async () => {
    let shouldContinue = true;
    while (shouldContinue) {
      if (!reader) {
        throw new Error("No reader found");
      }

      const decoder = new TextDecoder("utf-8");
      const { value: chunk, done: readerDone } = await reader.read();

      if (readerDone) {
        onStream({ text: "", isComplete: true });
        shouldContinue = false;
      } else {
        const chunkString = decoder.decode(chunk);
        onStream({ text: chunkString, isComplete: false });
      }
    }
  })();

  return reader;
};
