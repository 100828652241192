import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

export const aiContract = c.router({
  contentWriter: {
    method: "POST",
    path: "/ai/content-writer",
    metadata: {
      streams: true,
    },
    body: z.object({
      instructions: z.string(),
      content: z.string(),
      clientId: z.string(),
    }),
    responses: {
      200: z.any(),
      401: z.object({
        message: z.string(),
        success: z.boolean(),
      }),
    },
  },
  decideSearchContext: {
    method: "POST",
    path: "/ai/decide-search-context",
    body: z.object({
      searchValue: z.string(),
    }),
    responses: {
      200: z.object({
        context: z.array(z.enum(["journalists", "publications", "articles"])),
      }),
    },
  },
  chat: {
    method: "POST",
    path: "/ai/chat",
    metadata: {
      streams: true,
    },
    body: z.object({
      timezone: z.string(),
      history: z
        .object({
          role: z.enum(["system", "user", "assistant", "function"]),
          content: z.string(),
        })
        .array(),
    }),
    responses: {
      200: z.any(),
    },
  },
});
