import { useAuth } from "hooks/useAuth";

export const useGetClientTerminology = () => {
  const { workspace } = useAuth();
  return (input: string) => {
    if (workspace.type === "agency") {
      return input;
    }

    // Case-insensitive regex with replacement function that preserves case
    return input.replace(/client/gi, (match) => {
      if (match === match.toLowerCase()) return "brand";
      if (match === match.toUpperCase()) return "BRAND";
      if (match.charAt(0) === match.charAt(0).toUpperCase()) return "Brand";
      return "brand";
    });
  };
};
