import { Box, Text, Spinner, Flex } from "@chakra-ui/react";
import { SearchItem } from "../SearchItem";
import { useInfiniteQuery } from "react-query";
import { graphqlFetcher } from "generated/fetcher";
import {
  SearchArticlesDocument,
  SearchArticlesQuery,
  SearchArticlesQueryVariables,
} from "generated/graphql";
import { FaNewspaper } from "react-icons/fa";
import { subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Page, SelectableItem } from "../types";

interface ArticleSearchResultsProps {
  query: string;
  isSelected: boolean;
}

const PAGE_SIZE = 3;

export const ArticleSearchResults = ({
  query,
  isSelected,
}: ArticleSearchResultsProps) => {
  const navigate = useNavigate();

  const { data, isLoading } = useInfiniteQuery<SearchArticlesQuery>(
    ["quickSearchArticles", query],
    async ({ pageParam = 0 }) => {
      return await graphqlFetcher<
        SearchArticlesQuery,
        SearchArticlesQueryVariables
      >(SearchArticlesDocument, {
        query,
        skip: pageParam * PAGE_SIZE,
        take: PAGE_SIZE,
        startDate: subDays(new Date(), 7),
      })();
    },
    {
      enabled: query.length >= 3,
      getNextPageParam: (lastPage) => {
        if (lastPage.articles.length < PAGE_SIZE) return undefined;
        return 1; // We only want to show the first page in quick search
      },
    }
  );

  const articles = data?.pages[0]?.articles || [];

  if (isLoading) {
    return (
      <Box px="4" py="2">
        <Spinner size="sm" />
      </Box>
    );
  }

  if (articles.length === 0) return null;

  return (
    <>
      <Flex justifyContent="space-between" px="4" py="2">
        <Text
          fontSize="xs"
          fontWeight="medium"
          color="gray.500"
          textTransform="uppercase"
          display="flex"
          alignItems="center"
          gap={2}
        >
          Articles
        </Text>
        <Text
          as="a"
          href={`/news-search?query=${encodeURIComponent(query)}`}
          fontSize="xs"
          color="blue.500"
          _hover={{ textDecoration: "underline" }}
        >
          See all
        </Text>
      </Flex>
      {articles.map((article) => {
        const item: SelectableItem = {
          id: `article-${article.id}`,
          type: "article",
          element: {
            title: article.title,
            description: (
              <>
                <Text fontWeight="bold" fontSize="xs" color="gray.500">
                  {"url" in article ? article.publication.name : ""}
                </Text>
                {article.synthExtract.slice(0, 200)}...
              </>
            ),
            type: "route",
            icon: FaNewspaper,
            path: `/articles/${article.id}`,
          },
          section: "Articles",
        };

        return (
          <SearchItem
            key={item.id}
            item={item}
            onClick={() => {
              // Add to recent pages in localStorage
              const stored = localStorage.getItem("recentSearchPages");
              const recentPages = stored ? JSON.parse(stored) : [];
              const newRecentPage = {
                title: item.element.title,
                path: (item.element as Page).path,
                icon: (item.element as Page).icon,
                timestamp: Date.now(),
                type: "article" as const,
                // Store just the text content instead of React elements
                description:
                  "url" in article
                    ? `${
                        article.publication.name
                      }\n${article.synthExtract.slice(0, 200)}...`
                    : article.synthExtract.slice(0, 200) + "...",
              };
              const updated = [
                newRecentPage,
                ...recentPages.filter(
                  (p: any) => p.path !== (item.element as Page).path
                ),
              ].slice(0, 5);
              localStorage.setItem(
                "recentSearchPages",
                JSON.stringify(updated)
              );

              navigate(`/articles/${article.id}`);
            }}
            isSelected={isSelected}
          />
        );
      })}
    </>
  );
};
