import React from "react";
import { PermissionEnum } from "../generated/graphql";
import { WorkspacePermissions } from "@yaarn/shared/graphql";

export interface AuthenticatedUser {
  id: string;
  bio: string;
  name: string;
  email: string;
  imageUrl?: string | null;
  timezone: string;
  client: {
    id: string;
    name: string;
    logo?: string | null;
  } | null;
  role: {
    id: string;
    name: string;
    permissions: PermissionEnum[];
  };
  workspace: {
    id: string;
    name: string;
    logo?: string | null;
    hasPaidSubscription: boolean;
    createdAt: Date;
    permissions: WorkspacePermissions[];
    type: "agency" | "business";
  };
  managedClients: {
    id: string;
    name: string;
    logo?: string | null;
  }[];
}

export const AuthenticatedUserContext = React.createContext<AuthenticatedUser>({
  id: "",
  bio: "",
  name: "",
  email: "",
  timezone: "",
  client: null,
  role: {
    id: "",
    name: "",
    permissions: [],
  },
  workspace: {
    id: "",
    name: "",
    hasPaidSubscription: false,
    type: "agency",
    createdAt: new Date(0),
    permissions: [],
  },
  managedClients: [],
});
