import { Box, Text } from "@chakra-ui/react";
import { Page, SelectableItem } from "./types";
import { SearchItem } from "./SearchItem";
import { apiClient } from "services/apiClient";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { ArticleSearchResults } from "./search-types/ArticleSearchResults";
import { JournalistSearchResults } from "./search-types/JournalistSearchResults";
import { PublicationSearchResults } from "./search-types/PublicationSearchResults";

interface SearchResultsProps {
  items: Page[];
  selectableItems: SelectableItem[];
  selectedId: string | null;
  onItemClick: (item: SelectableItem) => void;
  searchValue: string;
  onAskAI: (question: string) => void;
}

export const SearchResults = ({
  items,
  selectableItems,
  selectedId,
  onItemClick,
  searchValue,
  onAskAI,
}: SearchResultsProps) => {
  const [searchContexts, setSearchContexts] = useState<
    ("journalists" | "publications" | "articles")[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSearchContexts = async () => {
      setIsLoading(true);
      try {
        const response = await apiClient.ai.decideSearchContext({
          body: { searchValue },
        });
        if (response.status === 200) {
          setSearchContexts(response.body.context);
        }
      } catch (error) {
        console.error("Error fetching search contexts:", error);
        setSearchContexts([]);
      } finally {
        setIsLoading(false);
      }
    };

    // Debounce the fetch call by 300ms
    const debouncedFetch = debounce(fetchSearchContexts, 300);

    if (searchValue.trim().length > 2) {
      // Only fetch if we have at least 3 characters
      debouncedFetch();
    } else {
      setSearchContexts([]);
    }

    // Cleanup
    return () => {
      debouncedFetch.cancel();
    };
  }, [searchValue]);

  // Group items by section
  const itemsBySection = selectableItems.reduce((acc, item) => {
    if (!acc[item.section]) {
      acc[item.section] = [];
    }
    acc[item.section].push(item);
    return acc;
  }, {} as Record<string, SelectableItem[]>);

  return (
    <>
      {Object.entries(itemsBySection).map(([section, sectionItems]) => (
        <Box key={section}>
          <Box px="4" py="2">
            <Text
              fontSize="xs"
              fontWeight="medium"
              color="gray.500"
              textTransform="uppercase"
            >
              {section}
            </Text>
          </Box>
          {sectionItems.map((item) => (
            <Box key={item.id} w="100%">
              <SearchItem
                item={item}
                onClick={onItemClick}
                isSelected={item.id === selectedId}
                isSubItem={item.type === "subpage"}
              />
            </Box>
          ))}
        </Box>
      ))}

      {/* Search context results */}
      {searchContexts.length > 0 && !isLoading && (
        <>
          {searchContexts.includes("articles") && (
            <ArticleSearchResults
              query={searchValue}
              isSelected={false} // This will be handled by the individual components
            />
          )}
          {searchContexts.includes("journalists") && (
            <JournalistSearchResults query={searchValue} isSelected={false} />
          )}
          {searchContexts.includes("publications") && (
            <PublicationSearchResults query={searchValue} isSelected={false} />
          )}
        </>
      )}
    </>
  );
};
