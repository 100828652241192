import { ArrowForwardIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl as ChakraFormControl,
  FormErrorMessage,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  ListItem,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Card } from "../Card";
import * as Yup from "yup";
import Logo from "./assets/logo-red-transparent-1024.png";
import { SupportModal } from "./SupportModal";
import { FcGoogle } from "react-icons/fc";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "./firebase-config";
import { apiClient } from "services/apiClient";
import { backendUrl } from "services/getBackendUrl";
import { workspaceId } from "services/getBackendUrl";

export const LoginCard = () => {
  const [hasRequestedLogin, setHasRequestedLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { requestLogIn } = useAuth();
  const toast = useToast();
  const [hasLoginError, setHasLoginError] = useState(false);
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceLogo, setWorkspaceLogo] = useState("");

  // Fetch workspace branding on component mount
  useEffect(() => {
    const fetchWorkspaceBranding = async () => {
      try {
        const response = await apiClient.signup.getWorkspaceInfo({
          params: { workspaceId },
        });

        if (response.status === 200) {
          setWorkspaceName(response.body.workspace.name || "");
          setWorkspaceLogo(response.body.workspace.logoUrl || "");
        }
      } catch (error) {
        console.error("Failed to fetch workspace branding:", error);
      }
    };

    fetchWorkspaceBranding();
  }, []);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("The email address must be valid").required(),
  });

  /**
   * Common function to fetch the /login endpoint, whether via email or OAuth.
   * This uses the contract route: apiClient.auth.requestLogin(...)
   */
  const requestLogin = async (payload: {
    email: string;
    workspace: string;
    oAuthToken?: string;
    provider?: "email" | "google" | "microsoft" | "apple";
  }) => {
    try {
      const response = await apiClient.auth.requestLogin({
        body: payload,
      });
      return response;
    } catch (error) {
      console.error("Login request failed:", error);
      throw error;
    }
  };

  const onLoginSubmit = async (values: typeof initialValues) => {
    setIsLoading(true);
    setHasLoginError(false);

    try {
      // Use the existing auth hook for email login
      const result = await requestLogIn(values.email);

      if (result.status === 200) {
        setHasRequestedLogin(true);
      } else if (result.status === 400) {
        setHasLoginError(true);
        toast({
          title: "Error logging in",
          description: result.body.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setHasLoginError(true);
        toast({
          title: "Error logging in",
          description: "Something went wrong.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setHasLoginError(true);
      toast({
        title: "Error",
        description: (error as any).message || "An error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Google OAuth handler that:
   * 1. Performs Firebase sign-in
   * 2. Extracts the ID token
   * 3. Calls requestLogin with { oAuthToken, provider }
   * 4. Redirects to backend with the returned token
   */
  const handleGoogleLogin = async () => {
    setIsLoading(true);
    setHasLoginError(false);

    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      const email = result.user.email;

      if (!email) {
        throw new Error("No email returned from Google.");
      }

      // Call requestLogin with OAuth token
      const response = await requestLogin({
        email,
        workspace: workspaceId,
        oAuthToken: idToken,
        provider: "google",
      });

      if (response.status === 200 && "token" in response.body) {
        const url = `${backendUrl}/get-token/${response.body.token}?workspaceId=${response.body.workspaceId}`;
        // Redirect to backend with the API token
        console.log("Redirecting to backend with token:", url);
        window.location.href = url;
      } else {
        if (response.status === 400) {
          throw new Error(response.body.message || "Failed to get login token");
        } else {
          throw new Error("An error occurred");
        }
      }
    } catch (error) {
      console.error("Google Login failed:", error);
      setHasLoginError(true);
      toast({
        title: "Login Failed",
        description:
          (error as any).message || "An error occurred during Google login",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <Flex direction="column" alignItems="center">
        <Image w="96" src={Logo} />

        <Flex mt={4} direction="column" alignItems="center">
          <Flex
            alignItems="center"
            gap={3}
            bg="gray.50"
            px={4}
            py={2}
            borderRadius="md"
            boxShadow="sm"
          >
            {workspaceLogo && (
              <Avatar
                src={process.env.REACT_APP_FILE_URL + workspaceLogo}
                size="md"
                name={workspaceName || "Workspace"}
              />
            )}

            <Text fontSize="xl" fontWeight="bold" color="gray.700">
              {workspaceName
                ? `Log in to ${workspaceName}`
                : "Log in to workspace"}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Divider mt={4} />

      <Container mt={8}>
        {!hasRequestedLogin ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onLoginSubmit}
          >
            {({
              handleSubmit,
              errors,
              values,
              setFieldValue,
              touched,
              isSubmitting,
            }) => (
              <Box
                as="form"
                onSubmit={handleSubmit as any}
                maxW="440px"
                mx="auto"
              >
                <Flex direction="column" gap={3} mb={6}>
                  {/* Google */}
                  <Button
                    size="lg"
                    width="100%"
                    variant="outline"
                    leftIcon={<FcGoogle size={20} />}
                    borderColor="gray.300"
                    bg="white"
                    _hover={{
                      bg: "gray.50",
                      borderColor: "gray.400",
                    }}
                    onClick={handleGoogleLogin}
                    isLoading={isLoading && !isSubmitting}
                    loadingText="Logging in with Google..."
                  >
                    Continue with Google
                  </Button>

                  <Flex align="center" my={4}>
                    <Box flex={1} h="1px" bg="gray.200" />
                    <Text
                      px={4}
                      color="gray.500"
                      fontSize="sm"
                      fontWeight="medium"
                    >
                      or
                    </Text>
                    <Box flex={1} h="1px" bg="gray.200" />
                  </Flex>
                </Flex>

                <ChakraFormControl isInvalid={!!errors.email && touched.email}>
                  <InputGroup size="lg">
                    <InputLeftAddon bg="gray.50">
                      <EmailIcon color="gray.500" />
                    </InputLeftAddon>
                    <Input
                      autoCapitalize="off"
                      autoCorrect="off"
                      autoComplete="off"
                      inputMode="email"
                      title="Email"
                      autoFocus
                      value={values.email}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const emailWithoutInvalidChars =
                          event.target.value.replace(/[()<>[\]:;,]/g, "");
                        setFieldValue(
                          "email",
                          emailWithoutInvalidChars.replaceAll(" ", "")
                        );
                      }}
                      name="email"
                      placeholder="john.smith@email.com"
                      bg="white"
                      borderWidth="1px"
                      _hover={{ borderColor: "gray.300" }}
                      _focus={{
                        borderColor: "blue.500",
                        boxShadow: "0 0 0 1px var(--chakra-colors-blue-500)",
                      }}
                    />
                  </InputGroup>
                  {errors.email && touched.email && (
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  )}
                </ChakraFormControl>

                <Box backgroundColor="gray.50" borderRadius={5} p={5} mt={5}>
                  We&apos;ll send you an email with a link that you can use to
                  log in.
                </Box>

                <Button
                  type="submit"
                  colorScheme="yaarnRed"
                  mt={6}
                  w="100%"
                  size="lg"
                  isLoading={isSubmitting}
                  loadingText="Sending login link..."
                  rightIcon={<ArrowForwardIcon />}
                >
                  Log in with email
                </Button>

                {hasLoginError && (
                  <Alert mt="3" borderRadius="md" status="error">
                    <AlertIcon />
                    <AlertDescription>
                      <Text>
                        There was an error logging you in. Please try again.
                      </Text>
                      <Text>This could be because:</Text>
                      <UnorderedList>
                        <ListItem>
                          Your login link has expired. In this case, try logging
                          in again. Make sure that the link you click is the
                          most recent one.
                        </ListItem>
                        <ListItem>
                          Your account was disabled. In this case, contact your
                          administrator.
                        </ListItem>
                      </UnorderedList>
                    </AlertDescription>
                  </Alert>
                )}
              </Box>
            )}
          </Formik>
        ) : (
          <Box backgroundColor="gray.50" borderRadius={5} p={5} mt={5}>
            Check your emails for a login link. If you can&apos;t find it, it
            might be in your spam.
          </Box>
        )}

        <Box mt="4">
          <Flex direction="column" gap={2}>
            <Flex justify="end">
              <SupportModal>
                <Text
                  cursor="pointer"
                  _hover={{ textDecoration: "underline" }}
                  fontSize="xs"
                >
                  Having trouble logging in? Click here to contact support.
                </Text>
              </SupportModal>
            </Flex>

            <Text fontSize="sm" color="gray.600" textAlign="center">
              Don't have an account for this workspace? Please contact your
              organization's administrator for an invite.
            </Text>
          </Flex>
        </Box>
      </Container>
    </Card>
  );
};
