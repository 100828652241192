import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { PermissionEnum, WorkspacePermissions } from "../graphql";

const c = initContract();

const userSchema = z.object({
  id: z.string(),
  name: z.string(),
  bio: z.string(),
  email: z.string(),
  imageUrl: z.string(),
  timezone: z.string(),
  client: z
    .object({
      id: z.string(),
      name: z.string(),
      logo: z.string().optional().nullable(),
    })
    .nullable(),
  role: z.object({
    id: z.string(),
    name: z.string(),
    permissions: z.array(z.nativeEnum(PermissionEnum)),
  }),
  isArchived: z.boolean(),
  workspace: z.object({
    id: z.string(),
    name: z.string(),
    logo: z.string().optional().nullable(),
    type: z.enum(["agency", "business"]),
    permissions: z.array(z.nativeEnum(WorkspacePermissions)),
    hasPaidSubscription: z.boolean(),
    createdAt: z.date(),
  }),
  managedClients: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      logo: z.string().optional().nullable(),
    })
  ),
});

const responseSchema = z.object({
  user: userSchema,
  successful: z.boolean(),
});

export const authenticationContract = c.router({
  requestLogin: {
    method: "POST",
    path: "/login",
    body: z.object({
      email: z.string().email(),
      workspace: z.string(),
      oAuthToken: z.string().optional(),
      provider: z.enum(["email", "google", "microsoft", "apple"]).optional(),
    }),
    responses: {
      200: z
        .object({
          message: z.string(),
          successful: z.boolean(),
        })
        .or(
          z.object({
            message: z.string(),
            successful: z.boolean(),
            token: z.string(),
            workspaceId: z.string(),
          })
        ),
      400: z.object({
        message: z.string(),
        successful: z.boolean(),
      }),
    },
  },
  getToken: {
    method: "GET",
    path: "/get-token/:token",
    pathParams: z.object({
      token: z.string(),
    }),
    query: z.object({
      workspaceId: z.string(),
    }),
    responses: {},
  },
  authenticate: {
    method: "POST",
    path: "/authenticate",
    body: z.object({
      token: z.string(),
      timezone: z.string(),
      workspace: z.string(),
    }),
    responses: {
      200: responseSchema,
      401: z.object({
        message: z.string(),
      }),
    },
  },
  notifications: {
    method: "POST",
    path: "/notifications",
    body: z.object({
      after: z.date({ coerce: true }).optional(),
    }),
    responses: {
      200: z.object({
        notifications: z
          .object({
            id: z.string(),
            title: z.string(),
            body: z.string(),
            imageUrl: z.string().optional().nullable(),
            link: z.string(),
            createdAt: z.date({ coerce: true }),
            read: z.boolean(),
          })
          .array(),
        mentionedUsers: z
          .object({
            id: z.string(),
            name: z.string(),
            imageUrl: z.string().optional().nullable(),
            bio: z.string(),
            timezone: z.string(),
            lastOnlineAt: z.string(),
          })
          .array(),
      }),
    },
  },
  logout: {
    method: "POST",
    path: "/logout",
    body: z.object({}),
    responses: {
      200: z.object({
        message: z.string(),
        successful: z.boolean(),
      }),
      400: z.object({
        message: z.string(),
        successful: z.boolean(),
      }),
    },
  },
  getWorkspaceFromToken: {
    method: "GET",
    path: "/workspace-from-token/:token",
    pathParams: z.object({
      token: z.string(),
    }),
    responses: {
      200: z.object({
        id: z.string(),
        name: z.string(),
        logo: z.string().optional().nullable(),
      }),
      400: z.object({
        message: z.string(),
      }),
    },
  },
});
