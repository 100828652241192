import { initContract } from "@ts-rest/core";
import { PublicationSchema } from "./publications";

const c = initContract();

import { z } from "zod";

const MetricsSchema = z.object({
  estimatedImpressions: z
    .number()
    .describe(
      "The estimated number of impressions the coverage piece received, based on the number of hits that the parent publication recieves."
    ),
});

const EntitySchema = z
  .object({
    name: z.string().describe("The name of the entity."),
    imageUrl: z
      .string()
      .nullable()
      .describe("A link to the logo of the entity."),
    id: z.string().describe("The ID of the entity."),
  })
  .describe("The entity that the coverage piece mentions.");

const SpokespersonSchema = z
  .object({
    name: z.string().describe("The name of the spokesperson."),
    imageUrl: z
      .string()
      .nullable()
      .describe("A link to a headshot of the spokesperson."),
    id: z.string().describe("The ID of the spokesperson."),
  })
  .describe("A spokesperson that the coverage piece mentions.");

const MentionSchema = z
  .object({
    entity: EntitySchema.describe(
      "The entity - client or competitor - that the coverage piece mentions."
    ),
    spokespeople: z
      .array(SpokespersonSchema)
      .describe("The spokespeople that the coverage piece mentions."),
    sentences: z
      .array(z.string())
      .describe("Sentences from the coverage piece that mention the entity."),
    positiveNews: z
      .array(z.string())
      .describe("Reasons that the news is positive toward the entity."),
    negativeNews: z
      .array(z.string())
      .describe("Reasons that the news is negative toward the entity."),
    summary: z
      .string()
      .describe("A summary of the sentiment of the mention toward the entity."),
    score: z
      .number()
      .min(0)
      .max(1)
      .describe(
        "The sentiment score of the mention, between 0 and 1, where 1 is fully positive sentiment."
      ),
    sentiment: z.enum(["positive", "negative", "neutral"]),
  })
  .describe(
    "A mention of an entity in the coverage piece, containing sentences with mentions, positive and negative news, and a summary."
  );

const QueryMatchSchema = z.object({
  query: z.string().describe("The search query that was matched"),
  count: z
    .number()
    .describe("Number of times this query appeared in the article text"),
});

export const CoveragePieceSchema = z
  .object({
    id: z.string().describe("The ID of the coverage piece."),
    url: z.string().describe("The URL of the coverage piece."),
    title: z
      .string()
      .describe(
        "The title of the coverage piece, from the parent publication."
      ),
    publication: PublicationSchema.describe(
      "The publication that the coverage piece was published in."
    ),
    publishedAt: z
      .string()
      .describe("The date the coverage piece was published."), // ISO 8601
    extract: z
      .string()
      .describe("A short, synthetically generated description of the article."),
    keywords: z
      .array(z.string())
      .describe("The keywords of the coverage piece."),
    metrics: MetricsSchema.describe(
      "An object with metrics about the coverage piece - such as estimated impressions."
    ),
    syndications: z
      .array(
        z.object({
          publication: PublicationSchema.describe(
            "The publication that the coverage piece was syndicated to."
          ),
          url: z.string().describe("The URL of the syndicated article."),
          metrics: MetricsSchema.describe("Metrics about the syndication."),
        })
      )
      .describe("Places that the coverage piece was syndicated to."),
    queryMatches: z
      .array(QueryMatchSchema)
      .optional()
      .describe(
        "Array of query matches with counts for each search query in the article text"
      ),
    mentions: z
      .array(MentionSchema)
      .describe("Mentions of the entity in the coverage piece."),
  })
  .describe(
    "A piece of coverage, containing a publication, metrics, syndications, mentions, and keywords."
  );

export const CoverageFiltersSchema = z.object({
  dateRange: z
    .object({
      start: z.date({ coerce: true }),
      end: z.date({ coerce: true }),
    })
    .optional()
    .describe(
      "Limit to coverage pieces published within the specified date range. Defaults to all."
    ),
  publications: z
    .array(z.string())
    .optional()
    .describe(
      "List of publication IDs to limit to. Any publication that matches any of the IDs will be included."
    ),
  tags: z
    .array(z.string())
    .optional()
    .describe(
      "List of tags to limit to. Any coverage piece that matches any of the tags will be included."
    ),
  entities: z
    .array(z.string())
    .optional()
    .describe(
      `List of entity IDs to limit to. Any coverage piece that mentions any of the entities will be included. Defaults
       to the client and all competitors. `
    ),
  countries: z
    .array(z.string())
    .optional()
    .describe(
      "List of country codes (ISO 3166-1 alpha-2) to limit to. Any coverage piece that is published by a publication located in any of the countries will be included."
    ),
  sentiment: z
    .enum(["positive", "negative", "neutral"])
    .optional()
    .describe(
      "Limit to coverage pieces with the specified sentiment. Defaults to all."
    ),
  queries: z
    .array(z.string())
    .optional()
    .describe(
      "List of search queries to count occurrences of in the article text. Returns counts in queryMatches field."
    ),
  select: z
    .array(
      z.enum([
        "id",
        "url",
        "title",
        "publication",
        "publishedAt",
        "extract",
        "keywords",
        "metrics",
        "syndications",
        "mentions",
        "queryMatches",
      ])
    )
    .optional()
    .describe(
      "List of fields to include in the response. If not provided, all fields will be returned."
    ),
});

// Type inference if needed
export type CoveragePiece = z.infer<typeof CoveragePieceSchema>;
export type CoverageFilters = z.infer<typeof CoverageFiltersSchema>;

export const coverageContract = c.router({
  get: {
    method: "GET",
    path: "/public/coverage/:clientId",
    description: `Find articles where a client is mentioned, or the client's competitors are mentioned. Example call: <pre><code>curl -X GET 'https://api.yaarn.ai/public/coverage/CLIENT_ID?dateRange[start]=2024-01-01&dateRange[end]=2024-03-20&publications[]=PUBLICATION_1_ID&publications[]=PUBLICATION_2_ID&countries[]=US&countries[]=GB&sentiment=positive' \
-H 'Authorization: Bearer YOUR_API_TOKEN'</code></pre> (where YOUR_API_TOKEN is your API key, CLIENT_ID is the ID of the client to get coverage for, and PUBLICATION_1_ID and PUBLICATION_2_ID are the IDs of the publications to limit to)`,
    pathParams: z.object({
      clientId: z
        .string()
        .describe(
          "The ID of the client to get coverage for. This can be found in the client's profile page."
        ),
    }),
    query: CoverageFiltersSchema,
    responses: {
      200: z.array(CoveragePieceSchema),
      404: z.object({
        message: z
          .string()
          .describe(
            "The client was not found. This could be because the client ID is invalid."
          ),
      }),
      403: z.object({
        message: z
          .string()
          .describe("You are not authorized to access this client."),
      }),
      500: z.object({
        message: z
          .string()
          .describe(
            "An internal server error occurred while fetching the coverage."
          ),
      }),
    },
  },
});
