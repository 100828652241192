import {
  Box,
  VStack,
  Text,
  Input,
  Flex,
  Icon,
  useToast,
  IconButton,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FaArrowLeft, FaPlus, FaChevronDown } from "react-icons/fa";
import { ChatbotMessageType } from "./types";
import { useState, useEffect, useCallback, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { v4 as uuid } from "uuid";
import { streamChat } from "services/streamAiResponse";
import { RecentPage } from "./types";
import { WiStars } from "react-icons/wi";
import { useAuth } from "hooks/useAuth";
import rehypeRaw from "rehype-raw";
import React from "react";
import { useCreateContentFromMarkdownMutation } from "generated/graphql";
import { useNavigate } from "react-router-dom";

interface ChatResultsProps {
  initialMessage?: string;
  onClose?: () => void;
  initialConversation: StoredConversation | null;
}

export interface StoredConversation {
  id: string;
  messages: ChatbotMessageType[];
  timestamp: number;
  title: string;
}

export const ChatResults = ({
  initialMessage,
  onClose,
  initialConversation,
}: ChatResultsProps) => {
  const [inputValue, setInputValue] = useState(initialMessage || "");
  const [messages, setMessages] = useState<ChatbotMessageType[]>(
    initialConversation?.messages ?? []
  );
  const [provisionalMessage, setProvisionalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [conversationId, setConversationId] = useState<string>("");
  const toast = useToast();
  const { imageUrl, name, id: userId, managedClients = [], client } = useAuth();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { mutateAsync: createContent } = useCreateContentFromMarkdownMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialConversation) {
      setConversationId(initialConversation.id);
      setMessages(initialConversation.messages);
    } else {
      const newConversationId = uuid();
      setConversationId(newConversationId);

      if (initialMessage) {
        handleSubmit(initialMessage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialConversation, initialMessage]);

  useEffect(() => {
    if (messages.length > 0 && conversationId) {
      const conversations = getStoredConversations();
      const title = messages[0].message.slice(0, 50) + "...";

      conversations[conversationId] = {
        id: conversationId,
        messages,
        timestamp: Date.now(),
        title,
      };

      localStorage.setItem("aiConversations", JSON.stringify(conversations));
    }
  }, [messages, conversationId]);

  const getStoredConversations = (): Record<string, StoredConversation> => {
    const stored = localStorage.getItem("aiConversations");
    return stored ? JSON.parse(stored) : {};
  };

  const handleSubmit = useCallback(
    async (message: string) => {
      const newMessages: ChatbotMessageType[] = [
        ...messages,
        { id: uuid(), from: "user" as const, message },
      ];
      setMessages(newMessages);
      setIsLoading(true);
      setInputValue("");

      try {
        let responseMessage = "";
        await streamChat({
          onStream: async (data) => {
            setProvisionalMessage((prev) => prev + data.text);
            responseMessage += data.text;

            if (data.isComplete) {
              setProvisionalMessage("");
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  id: uuid(),
                  from: "assistant",
                  message: responseMessage,
                },
              ]);
              setIsLoading(false);
            }
          },
          bodyData: newMessages,
        });
      } catch (error) {
        toast({
          title: "Error",
          description: (error as Error).message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    },
    [messages, toast]
  );

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const ArticleBlock = ({ content }: { content: string }) => {
    // Find the first heading or first line to use as title
    const firstHeadingMatch = content.match(/^#+ (.+)$/m);
    const title = firstHeadingMatch
      ? firstHeadingMatch[0]
      : content.split("\n")[0];
    const articleContent = content.replace(title, "").trim();

    const handleAddToLoom = async (clientId: string) => {
      try {
        const plainTitle =
          title.replace(/^#+ /, "").slice(0, 50) +
          (title.length > 50 ? "..." : "");
        const res = await createContent({
          client: clientId,
          createdBy: userId,
          content: content,
          name: plainTitle,
        });

        navigate(`/content-writer/${res.createContentFromMarkdown.id}`);
      } catch (error) {
        toast({
          title: "Error creating content",
          description: (error as Error).message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    const clients = [...(managedClients || []), client].filter(
      (v, i, a) => v && a.findIndex((t) => t?.id === v?.id) === i
    ) as {
      id: string;
      name: string;
    }[];

    return (
      <Box
        borderWidth="1px"
        borderRadius="lg"
        p={4}
        my={3}
        bg="white"
        boxShadow="sm"
      >
        <Text fontSize="lg" fontWeight="medium" mb={2}>
          <ReactMarkdown
            components={MarkdownComponents}
            rehypePlugins={[rehypeRaw as any]}
          >
            {title}
          </ReactMarkdown>
        </Text>
        <Box fontSize="md" color="gray.600" mb={4}>
          <ReactMarkdown
            components={MarkdownComponents}
            rehypePlugins={[rehypeRaw as any]}
          >
            {articleContent}
          </ReactMarkdown>
        </Box>
        <Menu>
          <MenuButton
            as={Button}
            size="sm"
            colorScheme="blue"
            rightIcon={<Icon as={FaChevronDown} />}
            leftIcon={<Icon as={FaPlus} />}
          >
            Add to Loom
          </MenuButton>
          <MenuList>
            {clients.map((client) => (
              <MenuItem
                key={client.id}
                onClick={() => handleAddToLoom(client.id)}
              >
                {client.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    );
  };

  const MarkdownComponents = {
    article: ({ children }: any) => {
      const content = React.Children.toArray(children)
        .map((child) => {
          if (typeof child === "string") return child;
          if (React.isValidElement(child)) {
            // Recursively get text from nested children
            const childContent = React.Children.toArray(
              (child.props as any).children
            )
              .map((grandChild) => {
                if (typeof grandChild === "string") return grandChild;
                if (React.isValidElement(grandChild)) {
                  return (grandChild.props as any).children || "";
                }
                return "";
              })
              .join("\n");
            return childContent || "";
          }
          return "";
        })
        .join("\n");

      return <ArticleBlock content={content} />;
    },
    code({ node, inline, className, children, ...props }: any) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          style={atomDark}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
    p: ({ children }: any) => (
      <Text mb={1} lineHeight="short">
        {children}
      </Text>
    ),
    h1: ({ children }: any) => (
      <Text fontSize="2xl" fontWeight="bold" mb={3}>
        {children}
      </Text>
    ),
    h2: ({ children }: any) => (
      <Text fontSize="xl" fontWeight="bold" mb={2}>
        {children}
      </Text>
    ),
    ul: ({ children }: any) => (
      <Box as="ul" pl={4} mb={2}>
        {children}
      </Box>
    ),
    ol: ({ children }: any) => (
      <Box as="ol" pl={4} mb={2}>
        {children}
      </Box>
    ),
    li: ({ children }: any) => (
      <Box as="li" mb={1}>
        {children}
      </Box>
    ),
    blockquote: ({ children }: any) => (
      <Box
        borderLeft="3px"
        borderColor="gray.300"
        pl={3}
        py={1}
        my={1}
        color="gray.700"
        bg="gray.50"
        borderRadius="sm"
      >
        {children}
      </Box>
    ),
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, provisionalMessage]);

  return (
    <Flex w="100%" h="100%" direction="column" bg="white" borderRadius="lg">
      <Flex
        w="100%"
        justify="space-between"
        align="center"
        px={3}
        py={2}
        borderBottom="1px"
        borderColor="gray.100"
      >
        <Text fontSize="sm" fontWeight="medium">
          Yaarn Bot
        </Text>
        <IconButton
          aria-label="Close chat"
          icon={<FaArrowLeft />}
          variant="ghost"
          size="sm"
          onClick={handleClose}
        />
      </Flex>

      <VStack
        w="100%"
        spacing={2}
        px={3}
        py={2}
        flex="1"
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            bg: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            bg: "gray.200",
            borderRadius: "full",
          },
        }}
      >
        {messages.length === 0 ? (
          <Flex
            w="100%"
            h="100%"
            direction="column"
            justify="center"
            align="center"
            color="gray.500"
            fontSize="sm"
            py={12}
            gap={3}
          >
            <Icon as={WiStars} boxSize={8} color="blue.400" />
            <VStack spacing={1}>
              <Text fontWeight="medium" fontSize="md" color="gray.700">
                Welcome to Yaarn Bot
              </Text>
              <Text color="gray.500" textAlign="center">
                Ask me anything about public relations
              </Text>
            </VStack>
          </Flex>
        ) : (
          <>
            {messages.map((message) => (
              <Flex
                key={message.id}
                w="100%"
                gap={2}
                alignItems="flex-start"
                justify={message.from === "user" ? "flex-end" : "flex-start"}
              >
                {message.from === "user" ? (
                  <Avatar
                    size="xs"
                    name={name}
                    src={process.env.REACT_APP_FILE_URL + imageUrl}
                    order={2}
                  />
                ) : (
                  <Icon
                    as={WiStars}
                    boxSize={3}
                    mt={1.5}
                    order={1}
                    color="gray.400"
                  />
                )}
                <Box
                  maxW="85%"
                  bg={message.from === "user" ? "gray.50" : "white"}
                  py={1.5}
                  px={2.5}
                  borderRadius="md"
                  order={message.from === "user" ? 1 : 2}
                  borderWidth="1px"
                  borderColor="gray.200"
                  fontSize="sm"
                >
                  <ReactMarkdown
                    components={MarkdownComponents}
                    rehypePlugins={[rehypeRaw as any]}
                  >
                    {message.message}
                  </ReactMarkdown>
                </Box>
              </Flex>
            ))}
          </>
        )}

        {isLoading && provisionalMessage && (
          <Flex w="100%" gap={2} alignItems="flex-start">
            <Icon as={WiStars} boxSize={3} mt={1.5} color="gray.400" />
            <Box
              maxW="85%"
              bg="white"
              py={1.5}
              px={2.5}
              borderRadius="md"
              borderWidth="1px"
              borderColor="gray.200"
              fontSize="sm"
            >
              <ReactMarkdown
                components={MarkdownComponents}
                rehypePlugins={[rehypeRaw as any]}
              >
                {provisionalMessage}
              </ReactMarkdown>
            </Box>
          </Flex>
        )}

        <div ref={messagesEndRef} />
      </VStack>

      <Flex px={3} py={2} borderTop="1px" borderColor="gray.100" align="center">
        <Icon as={WiStars} color="gray.400" mr="2" boxSize={3.5} />
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type your message..."
          variant="unstyled"
          _placeholder={{ color: "gray.400" }}
          fontSize="sm"
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              if (inputValue.trim()) {
                handleSubmit(inputValue);
              }
            }
          }}
        />
      </Flex>
    </Flex>
  );
};

const stripMarkdown = (text: string): string => {
  return text
    .replace(/\*\*(.*?)\*\*/g, "$1") // Bold
    .replace(/\*(.*?)\*/g, "$1") // Italic
    .replace(/\[(.*?)\]\(.*?\)/g, "$1") // Links
    .replace(/`(.*?)`/g, "$1") // Inline code
    .replace(/```[\s\S]*?```/g, "") // Code blocks
    .replace(/#{1,6}\s/g, "") // Headers
    .replace(/\n/g, " ") // Newlines to spaces
    .trim();
};

export const getSearchableConversations = (): RecentPage[] => {
  const conversations = JSON.parse(
    localStorage.getItem("aiConversations") || "{}"
  ) as Record<string, StoredConversation>;

  return Object.values(conversations)
    .sort((a, b) => b.timestamp - a.timestamp)
    .map((conv: StoredConversation) => {
      const firstUserMessage =
        conv.messages.find((msg) => msg.from === "user")?.message || "";
      const title =
        firstUserMessage.length > 60
          ? firstUserMessage.slice(0, 60) + "..."
          : firstUserMessage;

      const firstAIResponse =
        conv.messages.find((msg) => msg.from === "assistant")?.message || "";
      const cleanResponse = stripMarkdown(firstAIResponse);
      const description =
        cleanResponse.length > 100
          ? cleanResponse.slice(0, 100) + "..."
          : cleanResponse;

      const date = new Date(conv.timestamp).toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      return {
        title,
        description: `${description} (${date})`,
        path: "",
        type: "conversation" as const,
        icon: WiStars,
        timestamp: conv.timestamp,
        conversation: conv,
        onClick: () => {
          return conv;
        },
      };
    });
};
