import { Box } from "@chakra-ui/react";
import React from "react";
import { Sidebar } from "./Sidebar";
import Helmet from "react-helmet";
import { TopBar, TOP_BAR_HEIGHT } from "./TopBar";
import { useAuth } from "../../hooks/useAuth";

interface PageShellProps {
  title: string;
  children: React.ReactNode;
  backgroundColor?: string;
}

export const PageShell = ({
  title,
  children,
  backgroundColor,
}: PageShellProps) => {
  const { workspace } = useAuth();
  return (
    <Box bg={backgroundColor}>
      <Helmet>
        <title>
          {title} - {workspace.name ?? ""} Yaarn
        </title>
      </Helmet>
      <Sidebar />
      <TopBar />
      <Box ml="300px" py={3}>
        <Box pt={TOP_BAR_HEIGHT}>{children}</Box>
      </Box>
    </Box>
  );
};
