import { PermissionEnum } from "./generated/graphql";
import { lazyWithPreload, PreloadableComponent } from "react-lazy-with-preload";
import { IconType } from "react-icons";
import { BsBuilding, BsNewspaper } from "react-icons/bs";
import {
  FaBookOpen,
  FaCog,
  FaNewspaper,
  FaSearch,
  FaTasks,
  FaUsers,
  FaVoicemail,
} from "react-icons/fa";
import { WiStars } from "react-icons/wi";
import { MdWork } from "react-icons/md";

export enum DefaultVisibility {
  VISIBLE_TO_ANYONE = "VISIBLE_TO_ANYONE",
}

type RouteConfig =
  | {
      element: PreloadableComponent<() => JSX.Element>;
      permission: PermissionEnum | DefaultVisibility;
      icon?: IconType;
    }
  | {
      element: PreloadableComponent<() => JSX.Element>;
      permission: PermissionEnum | DefaultVisibility;
      title: string;
      description: string;
      icon?: IconType;
    };

const agencyRoutes: Record<string, RouteConfig> = {
  "/agency-dashboard": {
    element: lazyWithPreload(
      () => import("pages/agency/dashboard/AgencyDashboard")
    ),
    permission: PermissionEnum.CanViewAgencyDashboard,
    title: "Dashboard",
    description: "Overview of performance and metrics",
    icon: MdWork,
  },
  "/authors": {
    element: lazyWithPreload(() => import("pages/agency/authors/Authors")),
    permission: PermissionEnum.CanViewAgencyDashboard,
    title: "Journalists",
    description: "View a list of journalists and authors",
    icon: FaVoicemail,
  },
  "/authors/:id/edit": {
    element: lazyWithPreload(() => import("pages/agency/authors/AuthorEdit")),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/program-planner/client/:clientId": {
    element: lazyWithPreload(
      () => import("pages/agency/program-planner/ProgramPlanner")
    ),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/authors/:id": {
    element: lazyWithPreload(() => import("pages/agency/authors/Author")),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/competitor-analysis/client/:clientId": {
    element: lazyWithPreload(
      () => import("pages/agency/competitor-analysis/CompetitorAnalysis")
    ),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/competitors/:id/edit": {
    element: lazyWithPreload(
      () => import("pages/agency/competitor-analysis/CompetitorSettings")
    ),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/activities/client/:clientId": {
    element: lazyWithPreload(
      () => import("pages/agency/activities/Activities")
    ),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/content-writer": {
    element: lazyWithPreload(
      () => import("pages/agency/content-writer/ContentWriterRecent")
    ),
    permission: PermissionEnum.CanViewAgencyDashboard,
    title: "Loom",
    description: "Create and manage articles, press releases and more",
    icon: WiStars,
  },
  "/clients/:id": {
    element: lazyWithPreload(() => import("pages/agency/client/Client")),
    permission: PermissionEnum.CanViewAllClients,
  },
  "/clients": {
    element: lazyWithPreload(
      () => import("pages/agency/client-settings/ClientSettings")
    ),
    permission: PermissionEnum.CanEditClients,
    title: "Client Settings",
    description: "Manage client accounts and settings",
    icon: BsBuilding,
  },
  "/clients/:id/edit": {
    element: lazyWithPreload(() => import("pages/agency/client/ClientEdit")),
    permission: PermissionEnum.CanEditClients,
  },
  "/publications/:id": {
    element: lazyWithPreload(
      () => import("pages/agency/publications/Publication")
    ),
    permission: PermissionEnum.CanViewPublications,
  },
  "/publications": {
    element: lazyWithPreload(
      () => import("pages/agency/publications/Publications")
    ),
    permission: PermissionEnum.CanViewPublications,
    title: "Publications",
    description: "View and manage publications",
    icon: FaNewspaper,
  },
  "/coverage-tracker/clients/:id": {
    element: lazyWithPreload(
      () => import("pages/agency/coverage-tracker/CoverageTracker")
    ),
    permission: PermissionEnum.CanAddCoverage,
  },
  "/users": {
    element: lazyWithPreload(() => import("pages/agency/users/Users")),
    permission: PermissionEnum.CanViewAllUsers,
    title: "Users",
    description: "Manage user accounts and permissions",
    icon: FaUsers,
  },
  "/roles": {
    element: lazyWithPreload(() => import("pages/agency/Roles")),
    permission: PermissionEnum.CanViewAllUsers,
    title: "Roles",
    description: "Configure user roles and permissions",
  },
  "/news-wraps": {
    element: lazyWithPreload(() => import("pages/agency/news-wraps/NewsWraps")),
    permission: PermissionEnum.CanViewNewsWraps,
    title: "News Wraps",
    description: "View and manage news wraps",
    icon: BsNewspaper,
  },
  "/news-wraps/:id": {
    element: lazyWithPreload(() => import("pages/agency/news-wraps/NewsWrap")),
    permission: PermissionEnum.CanViewNewsWraps,
  },
  "/workspace": {
    element: lazyWithPreload(
      () => import("pages/agency/workspace/WorkspaceSettings")
    ),
    permission: PermissionEnum.CanEditWorkspace,
    title: "Workspace Settings",
    description: "Configure workspace preferences",
    icon: FaBookOpen,
  },
};

const clientRoutes: Record<string, RouteConfig> = {
  "/client-dashboard": {
    element: lazyWithPreload(
      () => import("pages/client/dashboard/ClientDashboard")
    ),
    permission: PermissionEnum.CanViewClientDashboard,
    title: "Client Dashboard",
    description: "Overview of client metrics and performance",
    icon: MdWork,
  },
  "/client/coverage": {
    element: lazyWithPreload(() => import("pages/client/coverage/Coverage")),
    permission: PermissionEnum.CanViewClientDashboard,
    title: "Coverage",
    description: "View media coverage and reports",
    icon: FaBookOpen,
  },
  "/client/activities": {
    element: lazyWithPreload(
      () => import("pages/client/activities/Activities")
    ),
    permission: PermissionEnum.CanViewClientDashboard,
    title: "Activities",
    description: "Track and manage client activities",
    icon: FaTasks,
  },
  "/client/competitor-analysis": {
    element: lazyWithPreload(
      () => import("pages/client/competitor-analysis/ClientCompetitorAnalysis")
    ),
    permission: PermissionEnum.CanViewClientDashboard,
    title: "Competitor Analysis",
    description: "Compare your performance with competitors",
    icon: FaSearch,
  },
  "/client/settings": {
    element: lazyWithPreload(
      () => import("pages/client/settings/ClientSettings")
    ),
    permission: PermissionEnum.CanViewClientDashboard,
    title: "Settings",
    description: "Manage client account settings",
    icon: FaCog,
  },
  "/client/content-writer": {
    element: lazyWithPreload(
      () => import("pages/client/content-writer/ClientContentWriter")
    ),
    permission: PermissionEnum.CanViewClientDashboard,
    title: "Loom",
    description: "Create and manage articles, press releases and more",
    icon: WiStars,
  },
};

export const routesWithPermissions: Record<string, RouteConfig> = {
  "/": {
    element: lazyWithPreload(() => import("pages/shared/DashboardRedirect")),
    permission: DefaultVisibility.VISIBLE_TO_ANYONE,
    title: "Home",
    description: "Navigate to your dashboard",
  },
  "/articles/:id": {
    element: lazyWithPreload(() => import("pages/shared/articles/Article")),
    permission: DefaultVisibility.VISIBLE_TO_ANYONE,
  },
  "/profile": {
    element: lazyWithPreload(() => import("pages/shared/Profile")),
    permission: PermissionEnum.CanViewSettings,
    title: "Profile",
    description: "View and edit your profile",
  },
  "/content-writer/:id": {
    element: lazyWithPreload(
      () => import("pages/agency/content-writer/ContentWriter")
    ),
    permission: DefaultVisibility.VISIBLE_TO_ANYONE,
  },
  "/news-search": {
    element: lazyWithPreload(
      () => import("pages/shared/news-search/NewsSearch")
    ),
    permission: DefaultVisibility.VISIBLE_TO_ANYONE,
    title: "News Search",
    description: "Search and discover news articles",
    icon: FaSearch,
  },
  "/news-search/similar/:articleId": {
    element: lazyWithPreload(
      () => import("pages/shared/news-search/SimilarArticles")
    ),
    permission: DefaultVisibility.VISIBLE_TO_ANYONE,
  },
  ...agencyRoutes,
  ...clientRoutes,
};
