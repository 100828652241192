import {
  Flex,
  Icon,
  Input,
  VStack,
  Collapse,
  IconButton,
} from "@chakra-ui/react";
import { FaSearch, FaArrowLeft } from "react-icons/fa";
import { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { routesWithPermissions } from "../../../routes";
import { useAuth } from "hooks/useAuth";
import { Page, RecentPage, SearchBoxProps, SelectableItem } from "./types";
import { SearchResults } from "./SearchResults";
import { RecentSearches } from "./RecentSearches";
import Fuse from "fuse.js";
import {
  ChatResults,
  getSearchableConversations,
  StoredConversation,
} from "./ChatResults";
import { WiStars } from "react-icons/wi";

export const SearchBox = ({ className }: SearchBoxProps) => {
  const { managedClients, hasPermission } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItems, setFilteredItems] = useState<Page[]>([]);
  const [recentPages, setRecentPages] = useState<RecentPage[]>([]);
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Add new state for chat
  const [isChatting, setIsChatting] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedConversation, setSelectedConversation] =
    useState<StoredConversation | null>(null);

  const [selectableItems, setSelectableItems] = useState<SelectableItem[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  // Load recent pages from localStorage on mount
  useEffect(() => {
    const stored = localStorage.getItem("recentSearchPages");
    if (stored) {
      setRecentPages(JSON.parse(stored));
    }
  }, []);

  // Add page to recent pages
  const addToRecentPages = (page: Page) => {
    const newRecentPage: RecentPage = { ...page, timestamp: Date.now() };
    const updated = [
      newRecentPage,
      ...recentPages.filter((p) => p.path !== page.path),
    ].slice(0, 5); // Keep only last 5 pages
    setRecentPages(updated);
    localStorage.setItem("recentSearchPages", JSON.stringify(updated));
  };

  // Update searchableItems to include AI conversations
  const searchableItems: Page[] = useMemo(() => {
    const routePages = Object.entries(routesWithPermissions)
      .map(([path, route]) => {
        // Skip if user doesn't have required permission
        if (!hasPermission(route.permission)) {
          return null;
        }

        // Only include routes that have both title and description
        if ("title" in route && "description" in route) {
          return {
            title: route.title,
            path,
            description: route.description,
            type: "route" as const,
            icon: route.icon,
          };
        }
        return null;
      })
      .filter(
        (page): page is NonNullable<typeof page> =>
          page !== null && !page.path.includes(":")
      );

    // Create client pages with their subpages
    const clientPages = managedClients.map((client) => ({
      title: client.name,
      path: `/clients/${client.id}`,
      description: `View ${client.name}'s profile`,
      type: "client" as const,
      clientLogo: client.logo,
      subPages: [
        {
          title: "Activities",
          path: `/activities/client/${client.id}`,
          description: `View activities for ${client.name}`,
          type: "client" as const,
          icon: routesWithPermissions["/client/activities"].icon,
        },
        {
          title: "Coverage",
          path: `/coverage-tracker/clients/${client.id}`,
          description: `View coverage for ${client.name}`,
          type: "client" as const,
          icon: routesWithPermissions["/client/coverage"].icon,
        },
        {
          title: "Competitor Analysis",
          path: `/competitor-analysis/client/${client.id}`,
          description: `View competitor analysis for ${client.name}`,
          type: "client" as const,
          icon: routesWithPermissions["/client/activities"].icon,
        },
      ],
    }));

    const conversationPages = getSearchableConversations();

    return [...routePages, ...clientPages, ...conversationPages];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managedClients, hasPermission, routesWithPermissions]);

  // Create Fuse instance for fuzzy search
  const fuse = useMemo(() => {
    return new Fuse(searchableItems, {
      keys: ["title", "description"],
      threshold: 0.3, // Lower threshold = more strict matching
      ignoreLocation: true,
      minMatchCharLength: 2,
    });
  }, [searchableItems]);

  // Filter items based on search input using fuzzy search
  useEffect(() => {
    // Skip the effect if searchValue hasn't changed
    const trimmedSearch = searchValue.trim();
    if (trimmedSearch === "") {
      // Only set if there are actually items to clear
      if (filteredItems.length > 0) {
        setFilteredItems([]);
      }
      return;
    }

    const results = fuse.search(trimmedSearch);
    const newItems = results.map((result) => result.item);

    // Only update if the results are different
    if (JSON.stringify(newItems) !== JSON.stringify(filteredItems)) {
      setFilteredItems(newItems);
    }
  }, [searchValue, fuse, filteredItems]);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Memoize searchable conversations
  const searchableConversations = useMemo(
    () => getSearchableConversations(),
    []
  );

  // Update the keyboard navigation effect to handle recent pages
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      // Don't handle keyboard navigation when in chat mode
      if (!isOpen || isChatting) return;

      const totalItems = searchValue
        ? filteredItems.length + searchableConversations.length + 1
        : recentPages.length + searchableConversations.length + 1;

      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          setSelectedIndex((prev) => (prev + 1) % totalItems);
          break;
        case "ArrowUp":
          event.preventDefault();
          setSelectedIndex((prev) => (prev - 1 + totalItems) % totalItems);
          break;
        case "Enter":
          if (event.target instanceof HTMLButtonElement) {
            return;
          }
          event.preventDefault();
          if (searchValue && selectedIndex === totalItems - 1) {
            handleChatStart(searchValue);
          } else if (searchValue && selectedIndex < filteredItems.length) {
            handleItemClick(filteredItems[selectedIndex]);
          } else if (!searchValue && recentPages[selectedIndex]) {
            navigate(recentPages[selectedIndex].path);
            setIsOpen(false);
          }
          break;
        case "Escape":
          setIsOpen(false);
          setSearchValue("");
          break;
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOpen,
    isChatting,
    selectedIndex,
    filteredItems,
    searchValue,
    recentPages,
    navigate,
    searchableConversations,
  ]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [searchValue]);

  const handleItemClick = (page: Page) => {
    if (page.type === "conversation" && page.conversation) {
      handleChatStart(undefined, page.conversation);
    } else if (page.path === "") {
      // This is the AI chat option
      handleChatStart(searchValue);
    } else {
      navigate(page.path);
      addToRecentPages(page);
      setIsOpen(false);
    }
    setSearchValue("");
  };

  const handleChatStart = async (
    initialMessage?: string,
    conversation?: StoredConversation
  ) => {
    setIsChatting(true);
    setIsOpen(true);
    setSelectedConversation(conversation || null);
    if (initialMessage) {
      setSearchValue("");
    }
  };

  // Update selectable items whenever the search results change
  useEffect(() => {
    const items: SelectableItem[] = [];

    // Add conversations
    if (searchValue) {
      filteredItems
        .filter((item) => item.type === "conversation")
        .forEach((conv, index) => {
          items.push({
            id: `conv-${index}`,
            type: "conversation",
            element: conv,
            section: "Related Conversations",
          });
        });
    }

    // Add recent conversations from searchableConversations
    if (!searchValue) {
      searchableConversations.forEach((conv, index) => {
        items.push({
          id: `recent-conv-${index}`,
          type: "conversation",
          element: conv.conversation!,
          section: "Recent Conversations",
        });
      });
    }

    // Add pages and subpages
    const pages = searchValue
      ? filteredItems.filter((page) => page.type !== "conversation")
      : recentPages;
    pages.forEach((page, index) => {
      items.push({
        id: `page-${index}`,
        type: "page",
        element: page,
        section: searchValue ? "Pages" : "Recent Pages",
      });

      // Add subpages if they exist
      if (page.subPages) {
        page.subPages.forEach((subpage, subIndex) => {
          items.push({
            id: `subpage-${index}-${subIndex}`,
            type: "subpage",
            element: subpage,
            section: "Pages",
          });
        });
      }
    });

    // Add AI option at the end
    if (searchValue) {
      items.push({
        id: "ai-option",
        type: "ai",
        element: {
          title: `Ask AI about: "${searchValue}"`,
          description: "Get AI assistance with your question",
          type: "route",
          icon: WiStars,
          path: "",
        },
        section: "Ask AI",
      });
    }

    setSelectableItems(items);
    setSelectedId(items.length > 0 ? items[0].id : null);
  }, [searchValue, filteredItems, recentPages, searchableConversations]);

  // Handle keyboard navigation
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (!isOpen || isChatting) return;

      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          setSelectedId((currentId) => {
            const currentIndex = selectableItems.findIndex(
              (item) => item.id === currentId
            );
            const nextIndex = (currentIndex + 1) % selectableItems.length;
            return selectableItems[nextIndex].id;
          });
          break;

        case "ArrowUp":
          event.preventDefault();
          setSelectedId((currentId) => {
            const currentIndex = selectableItems.findIndex(
              (item) => item.id === currentId
            );
            const prevIndex =
              (currentIndex - 1 + selectableItems.length) %
              selectableItems.length;
            return selectableItems[prevIndex].id;
          });
          break;

        case "Enter":
          event.preventDefault();
          if (selectedId) {
            const selectedItem = selectableItems.find(
              (item) => item.id === selectedId
            );
            if (selectedItem) {
              handleSelectedItem(selectedItem);
            }
          }
          break;

        case "Escape":
          setIsOpen(false);
          setSearchValue("");
          break;
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isChatting, selectableItems, selectedId]);

  const handleSelectedItem = (item: SelectableItem) => {
    if (item.type === "ai") {
      handleChatStart(searchValue);
    } else if (item.type === "conversation") {
      handleChatStart(undefined, item.element as StoredConversation);
    } else {
      const page = item.element as Page;
      navigate(page.path);
      if (item.type === "page") {
        addToRecentPages(page);
      }
      setIsOpen(false);
    }
    setSearchValue("");
  };

  return (
    <Flex position="relative" ref={wrapperRef} className={className}>
      <Flex
        as="form"
        alignItems="center"
        bg="white"
        borderRadius="lg"
        px="4"
        py="2"
        w="550px"
        border="1px solid"
        borderColor="gray.200"
        _hover={{ borderColor: "gray.300", shadow: "sm" }}
        transition="all 0.2s"
      >
        {isChatting ? (
          <IconButton
            aria-label="Back to search"
            icon={<FaArrowLeft />}
            variant="ghost"
            size="sm"
            mr="2"
            onClick={() => {
              setIsChatting(false);
              setSearchValue("");
            }}
          />
        ) : (
          <Icon as={FaSearch} color="gray.400" mr="2" />
        )}
        <Input
          variant="unstyled"
          placeholder={
            isChatting ? "Chat with AI..." : "Search or ask anything..."
          }
          _placeholder={{ color: "gray.400" }}
          fontSize="sm"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            setIsOpen(true);
          }}
          onFocus={() => setIsOpen(true)}
        />
      </Flex>

      <Collapse in={isOpen}>
        <VStack
          position="absolute"
          top="100%"
          left="0"
          right="0"
          mt="2"
          bg="white"
          borderRadius="lg"
          shadow="xl"
          border="1px solid"
          borderColor="gray.200"
          maxH="calc(100vh - 200px)"
          overflowY="scroll"
          spacing={0}
          zIndex={1000}
          p="2"
          w="100%"
          align="stretch"
          sx={{
            "&::-webkit-scrollbar": {
              display: "block",
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "rgba(0,0,0,0.1)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(0,0,0,0.2)",
              borderRadius: "4px",
            },
          }}
        >
          {isChatting ? (
            <ChatResults
              initialMessage={searchValue}
              onClose={() => {
                setIsChatting(false);
                setSelectedConversation(null);
                setSearchValue("");
              }}
              initialConversation={selectedConversation}
            />
          ) : searchValue ? (
            <SearchResults
              items={filteredItems}
              selectableItems={selectableItems}
              selectedId={selectedId}
              onItemClick={handleSelectedItem}
              searchValue={searchValue}
              onAskAI={handleChatStart}
            />
          ) : (
            <RecentSearches
              selectableItems={selectableItems}
              selectedId={selectedId}
              onItemClick={handleSelectedItem}
              searchValue={searchValue}
              onAskAI={handleChatStart}
            />
          )}
        </VStack>
      </Collapse>
    </Flex>
  );
};
