import { Box, Flex, Icon, Text, Avatar } from "@chakra-ui/react";
import { Page, SelectableItem } from "./types";
import { WiStars } from "react-icons/wi";
import { format } from "date-fns";
import { StoredConversation } from "./ChatResults";

interface SearchItemProps {
  item: SelectableItem;
  onClick: (item: SelectableItem) => void;
  isSubItem?: boolean;
  isSelected?: boolean;
}

export const SearchItem = ({
  item,
  onClick,
  isSubItem = false,
  isSelected = false,
}: SearchItemProps) => {
  const element = item.element as Page | StoredConversation;
  const isConversation = "messages" in element;

  return (
    <Box
      w="100%"
      px="4"
      py={isSubItem ? "2" : "3"}
      cursor="pointer"
      borderRadius="lg"
      bg={isSelected ? "blue.50" : "white"}
      _hover={{ bg: isSelected ? "blue.100" : "gray.50" }}
      onClick={() => onClick(item)}
      as="button"
      role="option"
      aria-selected={isSelected}
      textAlign="left"
    >
      <Flex alignItems="center">
        {isConversation ? (
          <Icon as={WiStars} mr={3} boxSize="20px" color="purple.500" />
        ) : element.type === "client" &&
          "clientLogo" in element &&
          element.clientLogo ? (
          <Avatar
            src={element.clientLogo}
            name={"title" in element ? element.title : ""}
            size="sm"
            mr={3}
          />
        ) : "icon" in element && element.icon ? (
          <Icon
            as={element.icon}
            mr={3}
            boxSize={isSubItem ? "16px" : "20px"}
            color="gray.500"
          />
        ) : null}
        <Box flex="1">
          <Text fontSize="sm" fontWeight={!isSubItem ? "medium" : undefined}>
            {isConversation
              ? format(new Date(element.timestamp), "MMM d, yyyy")
              : element.title}
          </Text>
          {isConversation ? (
            <Text fontSize="xs" color="gray.500" mt="0.5" noOfLines={2}>
              {element.messages[element.messages.length - 1]?.message || ""}
            </Text>
          ) : (
            "description" in element &&
            element.description && (
              <Text
                fontSize="xs"
                color="gray.500"
                mt={!isSubItem ? "0.5" : undefined}
              >
                {element.description}
              </Text>
            )
          )}
        </Box>
        {!isSubItem && !isConversation && element.type === "client" && (
          <Text
            ml={2}
            fontSize="xs"
            px={2}
            py={0.5}
            borderRadius="full"
            bg="blue.100"
            color="blue.700"
          >
            Client
          </Text>
        )}
      </Flex>
    </Box>
  );
};
