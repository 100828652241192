import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  LinkBox,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { format, formatDistance } from "date-fns";
import { FaBell } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Card } from "../Card";
import {
  MentionedUser,
  RenderMentions,
} from "components/forms/mentions-field/RenderMentions";

interface NotificationTrayItemProps {
  id: string;
  read: boolean;
  imageUrl?: string | null;
  title: string;
  users: MentionedUser[];
  body: string;
  createdAt: Date;
  link: string;
  onDelete(): void;
}

export const NotificationTrayItem = ({
  read,
  imageUrl,
  title,
  users,
  body,
  createdAt,
  link,
  onDelete,
}: NotificationTrayItemProps) => {
  return (
    <Link to={"/" + link}>
      <LinkBox>
        <Card
          padding="3"
          transition="ease 200ms all"
          backgroundColor={read ? "white" : "orange.50"}
          _hover={{ transform: "translateX(2px)", shadow: "sm" }}
        >
          <Flex gap="3" align="flex-start">
            {/* Avatar/Icon Section */}
            {imageUrl ? (
              <Image
                borderRadius="full"
                boxSize="40px"
                src={process.env.REACT_APP_FILE_URL! + imageUrl}
                objectFit="cover"
              />
            ) : (
              <Flex
                boxSize="40px"
                align="center"
                justify="center"
                bg="gray.100"
                borderRadius="full"
              >
                <Icon as={FaBell} color="gray.500" boxSize="20px" />
              </Flex>
            )}

            {/* Content Section */}
            <Box flex="1">
              <Flex justify="space-between" align="center" mb="1">
                <Text fontWeight="medium" fontSize="sm" noOfLines={1}>
                  {title}
                </Text>
                <Button
                  size="xs"
                  variant="ghost"
                  colorScheme="green"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDelete();
                  }}
                  _hover={{ bg: "green.50" }}
                >
                  <CheckIcon />
                </Button>
              </Flex>

              <Text fontSize="sm" color="gray.600" noOfLines={2} mb="1">
                <RenderMentions content={body} mentionedUsers={users} />
              </Text>

              <Tooltip label={format(createdAt, "dd/MM/yyyy hh:mm aaa")}>
                <Text fontSize="xs" color="gray.500">
                  {formatDistance(createdAt, new Date())} ago
                </Text>
              </Tooltip>
            </Box>
          </Flex>
        </Card>
      </LinkBox>
    </Link>
  );
};
