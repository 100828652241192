import { EmailIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Text,
  Button,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputControl, TextareaControl, SubmitButton } from "formik-chakra-ui";
import { requestSupportEmail } from "services/requestSupportEmail";
import * as Yup from "yup";

interface SupportModalProps {
  children: React.ReactNode;
  initialValues?: {
    name: string;
    email: string;
  };
}

export const SupportModal = ({
  children,
  initialValues,
}: SupportModalProps) => {
  const { onClose, onOpen, isOpen } = useDisclosure();

  const initVals = {
    name: initialValues?.name || "",
    email: initialValues?.email || "",
    subject: "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("You must enter a name.")
      .max(100, "Name must be less than 100 characters"),
    email: Yup.string()
      .required("You must enter an email.")
      .email("The email must be valid."),
    subject: Yup.string()
      .required("Please provide a subject.")
      .max(200, "Subject must be less than 200 characters"),
    message: Yup.string()
      .required("You must enter a message.")
      .max(2000, "Message must be less than 2000 characters")
      .min(10, "Message must be at least 10 characters"),
  });

  const toast = useToast();

  const handleSubmit = async (
    values: typeof initVals,
    { setSubmitting }: any
  ) => {
    try {
      await requestSupportEmail(
        values.email,
        values.name,
        values.subject + "\n\n" + values.message
      );
      toast({
        title: "Support request sent",
        description:
          "Your support request has been sent. We will get back to you as soon as possible.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: "Couldn't send support request",
        description:
          "There was an error sending the support request. Please try again or email contact@yaarn.ai directly.",
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Box _hover={{ textDecor: "none" }} onClick={onOpen}>
        {children}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Support</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Formik
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              initialValues={initVals}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  {!initialValues && (
                    <Box mb={4}>
                      <InputControl
                        name="name"
                        label="Name"
                        inputProps={{
                          placeholder: "Enter your full name",
                        }}
                      />
                      <InputControl
                        mt={3}
                        name="email"
                        label="Email"
                        inputProps={{
                          placeholder: "Enter your email address",
                        }}
                      />
                    </Box>
                  )}
                  <InputControl
                    name="subject"
                    label="Subject"
                    inputProps={{
                      placeholder: "What is your inquiry about?",
                    }}
                  />
                  <Box mt={3} position="relative">
                    <TextareaControl
                      name="message"
                      label="Message"
                      textareaProps={{
                        placeholder:
                          "Please describe your issue or question in detail...",
                        minHeight: "150px",
                      }}
                    />
                    <Text
                      position="absolute"
                      right={2}
                      bottom={2}
                      fontSize="sm"
                      color="gray.500"
                    >
                      {values.message.length}/2000
                    </Text>
                  </Box>
                  <Flex justify="space-between" align="center" mt={6}>
                    <Button variant="ghost" onClick={onClose}>
                      Cancel
                    </Button>
                    <SubmitButton
                      colorScheme="yaarnRed"
                      leftIcon={<EmailIcon />}
                      isLoading={isSubmitting}
                      loadingText="Sending..."
                    >
                      Send message
                    </SubmitButton>
                  </Flex>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
