import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useRef } from "react";
import { FaBell } from "react-icons/fa";
import { Virtuoso } from "react-virtuoso";
import { NotificationTrayItem } from "./NotificationTrayItem";
import { NotificationsContext } from "../../providers/NotificationsProvider";
import { CheckIcon } from "@chakra-ui/icons";

export const NotificationsTray = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnRef = useRef(null);

  const {
    setNotificationsViewed,
    notifications,
    mentionedUsers,
    deleteNotification,
    clearNotifications,
  } = useContext(NotificationsContext);

  const notificationLength = notifications.filter((n) => !n.read).length ?? 0;

  return (
    <>
      <Box
        px="4"
        h="100%"
        cursor="pointer"
        onClick={async () => {
          if (!isOpen) {
            setTimeout(() => {
              setNotificationsViewed();
            }, 5000);
          }
          onOpen();
        }}
        transition="ease 400ms all"
        _hover={{ backgroundColor: "rgba(0,0,0,0.05)" }}
        py={1.5}
      >
        <Flex h="100%" alignItems="center">
          <Box display="inline">
            <Icon fontSize="x-large" as={FaBell} />
            {notificationLength > 0 && (
              <Badge
                borderRadius={999}
                border="3px solid"
                borderColor="red.200"
                px="1"
                backgroundColor="red.500"
              >
                <Text fontSize="8pt" fontWeight="extrabold" color="white">
                  {notificationLength}
                </Text>
              </Badge>
            )}
          </Box>
        </Flex>
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent
          backdropFilter="blur(10px)"
          backgroundColor="whiteAlpha.900"
        >
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex align="center" justify="space-between" w="100%">
              <Text>Notifications</Text>
              <Button
                isDisabled={notifications.length === 0}
                onClick={clearNotifications}
                leftIcon={<CheckIcon />}
                mr="8"
                colorScheme="yaarnRed"
                size="sm"
              >
                <Text fontSize="xs">Clear all</Text>
              </Button>
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            {notifications.length === 0 ? (
              <Text>You don&apos;t have any notifications yet.</Text>
            ) : (
              <Box height="100%">
                <Virtuoso
                  style={{ height: "100%" }}
                  data={notifications}
                  itemContent={(index, notification) => (
                    <NotificationTrayItem
                      onDelete={async () => {
                        deleteNotification(notification.id);
                      }}
                      users={mentionedUsers}
                      key={notification.id}
                      {...notification}
                    />
                  )}
                />
              </Box>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
